<template>
  <div class="after-sale bsbb">
    <van-nav-bar title="售后详情" left-arrow @click-left="onClickLeft" :border="false" />
    <div class="content mt20 p20 bsbb">
      <div class="top">
        <p class="font-size-38 fw5 mb20">退款成功</p>
        <p class="font-size-16" style="color: #999">预计最晚2022年12月1日到账</p>
      </div>
      <div class="detail mt20">
        <div class="df aic jcsb mb20">
          <div class="df fdc">
            <p class="font-size-28 mb20 mt10" style="color:#999">退款金额</p>
            <p style="color:red" class="fz16">已退回至原账户</p>
          </div>
          <div style="color:#000" class="fz16">¥ <span class="fz28">27.28</span></div>
        </div>
        <div class="df aic jcsb mb20">
          <div class="df fdc">
            <p class="font-size-28 mb20 mt10" style="color:#999">退款红包</p>
            <p style="color:red" class="fz16">已退回至券包</p>
          </div>
          <div style="color:#000" class="fz16">1张红包</div>
        </div>
      </div>
      <div class="step mt40">
        <div class="fz28 mb20">退款流程</div>
        <div style="margin-left:20px">
          <van-steps active-color="red" direction="vertical" :active="0">
            <van-step>
              <h3>退款已到账</h3>
              <p>2016-07-12 12:40</p>
            </van-step>
            <van-step>
              <h3>鲜果拾代已受理退款</h3>
              <p>2016-07-11 10:00</p>
            </van-step>
            <van-step>
              <h3>鲜果拾代审核通过</h3>
              <p>2016-07-10 09:30</p>
            </van-step>
          </van-steps>
        </div>
      </div>
      <div class="msg mt40">
        <div class="fz28 fw5">退款信息</div>
        <div class="shop mt40 df aic jcsb">
          <div class="df aic">
            <img src="../../assets/images/xg/hotshop1.jpg" style="width:60px" />
            <div class="df fdc">
              <p class="fz28 fw7 mb20">菜大全鲜果蔬菜成都店</p>
              <p class="fz16" style="color:#ddd">香蕉500gx1</p>
            </div>
          </div>
          <div style="color:#000" class="fz16"> <span style="color:#999">合计</span> ¥ <span class="fz28">27.28</span>
          </div>
        </div>
        <div class="detailmsg mt40 df fdc fz16">
          <div class="df aic jcsb mb20">
            <p style="color:#999">退款原因</p>
            <p>买错/买多/买少/</p>
          </div>
          <div class="df aic jcsb mb20">
            <p style="color:#999">订单号码</p>
            <p>4050 3500 9693 3905 055</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.after-sale {
  width: 100%;
  height: 100%;
  padding: 20px 30px;

  .content {
    width: 690px;
    height: 1982px;
    border-radius: 30px 30px 0px 0px;
    background: rgba(255, 255, 255, 1);
    margin: 20px auto;

    .top {
      padding-bottom: 20px;
      border-bottom: 1px solid #ddd;
    }

    .detail {
      padding-bottom: 20px;
      border-bottom: 1px solid #ddd;
    }

    .step {
      padding-bottom: 30px;
      border-bottom: 1px solid #ddd;
    }

    .msg {
      .shop {
        img {
          border-radius: 20px;
          margin-right: 20px;
        }
      }
    }
  }
}
</style>